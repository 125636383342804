import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/sellerLoginPage.vue')
  },
  {
    path: '/admin2023',
    name: 'adminlogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/loginPage.vue')
  },
  {
    path: '/delivery2023',
    name: 'deliveryLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/deliveryLoginPage.vue')
  },
  {
    path: '/category',
    name: 'category',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/product/categoryPage.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/product/productPage.vue')
  },
  {
    path: '/deletedProduct',
    name: 'deletedProduct',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/product/deletedProductPage.vue')
  },
  {
    path: '/sellerDeletedProduct',
    name: 'sellerDeletedProduct',
    component: () => import(/* webpackChunkName: "about" */ '../views/Seller/master_data/sellerDeletedProductPage.vue')
  },
  {
    path: '/subcategory',
    name: 'subcategory',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/product/subcategoryPage.vue')
  },
  {
    path: '/seller',
    name: 'seller',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Seller/sellerPage.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/order/orderPage.vue')
  },
  {
    path: '/orderDetailAdmin',
    name: 'orderDetailAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/order/orderDetailPage.vue')
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/order/orderListPage.vue')
  },
  {
    path: '/seller_profile',
    name: 'seller_profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Seller/sellerProfile.vue')
  },
  {
    path: '/sellerDetail',
    name: 'sellerDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Seller/sellerDetailPage.vue')
  },
  {
    path: '/seller_product',
    name: 'seller_product',

    component: () => import(/* webpackChunkName: "about" */ '../views/Seller/master_data/addProductPage.vue')
  },
  {
    path: '/seller_manage_product',
    name: 'seller_manage_product',
    component: () => import(/* webpackChunkName: "about" */ '../views/Seller/master_data/manageProductPage.vue')

  },

  {
    path: '/seller_manage_product_item/:id',
    name: 'seller_manage_product_item',
    component: () => import(/* webpackChunkName: "about" */ '../views/Seller/master_data/manageItemPage.vue')

  },

  {
    path: '/seller_add_item',
    name: 'seller_add_item',
    component: () => import(/* webpackChunkName: "about" */ '../views/Seller/master_data/addItemPage.vue')

  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/customer/couponListsPage.vue')

  },
  //region start
  {
    path: '/region',
    name: 'region',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/order/regionPage.vue')

  },
  {
    path: '/manageRegion',
    name: 'manageRegion',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/order/manageRegionPage.vue')

  },
  //region end
  {
    path: '/editor_profile',
    name: 'editor_profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/editor/editorProfile.vue')
  },
  {
    path: '/editor',
    name: 'editor',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/editor/editorPage.vue')
  },
  {
    path: '/campangeCreate',
    name: 'campangeCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/campange/campangeCreatePage.vue')

  },
  {
    path: '/campangeList',
    name: 'campangeList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/campange/campangeListPage.vue')

  },
  {
    path: '/campangeProduct',
    name: 'campangeProduct',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/campange/campangeProductPage.vue')

  },
  {
    path: '/campangeDetail',
    name: 'campangeDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/campange/campangeDetailPage.vue')

  },
  {
    path: '/sellerCampange',
    name: 'sellerCampange',
    component: () => import(/* webpackChunkName: "about" */ '../views/Seller/master_data/sellerCampangePage.vue')

  },

  {
    path: '/sellerJoinCampange',
    name: 'sellerJoinCampange',
    component: () => import(/* webpackChunkName: "about" */ '../views/Seller/master_data/joinSellerCampangePage.vue')

  },
  {
    path: '/seller_orderList',
    name: 'seller_orderList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Seller/order/sellerOrderListPage.vue')
  },
  {
    path: '/questionAnswer',
    name: 'questionAnswer',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/question/questionPage.vue')
  },
  {
    path: '/questionAdmin',
    name: 'questionAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/question/questionAdminPage.vue')
  },
  {
    path: '/reviewAdmin',
    name: 'reviewAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/review/reviewAdminPage.vue')
  },
   {
    path: '/reviewSeller',
    name: 'reviewSeller',
    component: () => import(/* webpackChunkName: "about" */ '../views/Seller/review/reviewSellerPage.vue')
  },
  {
    path: '/answerAdmin',
    name: 'answerAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/question/answerAdminPage.vue')
  },
   {
    path: '/reviewDetailAdmin',
    name: 'reviewDetailAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/review/reviewDetailPage.vue')
  },
  {
    path: '/bannerPage',
    name: 'bannerPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/banner/bannerPage.vue')
  },
  {
    path: '/brandPage',
    name: 'brandPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/brand/brandPage.vue')
  },
   {
    path: '/largeItemTypePage',
    name: 'largeItemTypePage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/largeItem/largeItemPage.vue')
  },
  {
    path: '/financePage',
    name: 'financePage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/finance/financePage.vue')
  },
   {
    path: '/totalFinancePage',
    name: 'totalFinancePage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/finance/totalFinancePage.vue')
  },
   {
    path: '/financeDetailPage',
    name: 'financeDetailPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/finance/financeDetailPage.vue')
  },
  {
    path: '/sellerFinancePage',
    name: 'sellerFinancePage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Seller/finance/sellerFinanceDetailPage.vue')
  },
  {
    path: '/deliveryPage',
    name: 'deliveryPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/delivery/deliveryPage.vue')
  },
  {
    path: '/deliverySearchPage',
    name: 'deliverySearchPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Delivery/deliverySearchPage.vue')
  },
  {
    path: '/deliveryDetailPage',
    name: 'deliveryDetailPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Delivery/deliveryDetailPage.vue')
  },
  {
    path: '/deletedSeller',
    name: 'deletedSeller',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Seller/deletedSellerPage.vue')
  },
   {
    path: '/coupon_lists',
    name: 'coupon_lists',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/customer/couponListsPage.vue')
  },
  {
    path: '/test',
    name: 'testPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (sessionStorage.getItem('Token') == null) next({ name: 'login' })
//   else next()
// })
// router.beforeEach((to, from, next) => {
//   if (sessionStorage.getItem('Token') == null) next({ name: 'login' })
//   else next()
// })
router.beforeEach((to, from,next) => {
  if (to.name != 'login' && to.name != 'adminlogin' && to.name != 'deliveryLogin' && sessionStorage.getItem('Token') == null) {
    // redirect the user to the login page
    next({name: 'login'})
  }else {
    next()  
  }
})


export default router
