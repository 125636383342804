import { createStore } from 'vuex'

export default createStore({
  state: {
    url : 'https://test-backend.mgdmall.com.mm/api/', 
    // url : 'http://localhost:8000/api/',
    role: '',
    order_id :null,
    user:{},
    seller: {},
  },
  getters: {
    getRole:state=>state.role,
    getUser:state=>state.user,
    getSeller:state=>state.seller
  },
  mutations: {
    
  },
  actions: {
    setRole:({state},value) => state.role = value,
    setUser:({state},value) => state.user = value,
    setSeller:({state},value) => state.seller = value
  },
  modules: {
  }
})
